(function($) {
  $.extend($.summernote.lang, {
    'da-DK': {
      font: {
        bold: 'Fed',
        italic: 'Kursiv',
        underline: 'Understreget',
        clear: 'Fjern formatering',
        height: 'Højde',
        name: 'Skrifttype',
        strikethrough: 'Gennemstreget',
        subscript: 'Sænket skrift',
        superscript: 'Hævet skrift',
        size: 'Skriftstørrelse',
      },
      image: {
        image: 'Billede',
        insert: 'Indsæt billede',
        resizeFull: 'Original størrelse',
        resizeHalf: 'Halv størrelse',
        resizeQuarter: 'Kvart størrelse',
        floatLeft: 'Venstrestillet',
        floatRight: 'Højrestillet',
        floatNone: 'Fjern formatering',
        shapeRounded: 'Form: Runde kanter',
        shapeCircle: 'Form: Cirkel',
        shapeThumbnail: 'Form: Miniature',
        shapeNone: 'Form: Ingen',
        dragImageHere: 'Træk billede hertil',
        dropImage: 'Slip billede',
        selectFromFiles: 'Vælg billed-fil',
        maximumFileSize: 'Maks fil størrelse',
        maximumFileSizeError: 'Filen er større end maks tilladte fil størrelse!',
        url: 'Billede URL',
        remove: 'Fjern billede',
        original: 'Original',
      },
      video: {
        video: 'Video',
        videoLink: 'Video Link',
        insert: 'Indsæt Video',
        url: 'Video URL?',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion eller Youku)',
      },
      link: {
        link: 'Link',
        insert: 'Indsæt link',
        unlink: 'Fjern link',
        edit: 'Rediger',
        textToDisplay: 'Visningstekst',
        url: 'Hvor skal linket pege hen?',
        openInNewWindow: 'Åbn i nyt vindue',
      },
      table: {
        table: 'Tabel',
        addRowAbove: 'Tilføj række over',
        addRowBelow: 'Tilføj række under',
        addColLeft: 'Tilføj venstre kolonne',
        addColRight: 'Tilføj højre kolonne',
        delRow: 'Slet række',
        delCol: 'Slet kolonne',
        delTable: 'Slet tabel',
      },
      hr: {
        insert: 'Indsæt horisontal linje',
      },
      style: {
        style: 'Stil',
        p: 'p',
        blockquote: 'Citat',
        pre: 'Kode',
        h1: 'Overskrift 1',
        h2: 'Overskrift 2',
        h3: 'Overskrift 3',
        h4: 'Overskrift 4',
        h5: 'Overskrift 5',
        h6: 'Overskrift 6',
      },
      lists: {
        unordered: 'Punktopstillet liste',
        ordered: 'Nummereret liste',
      },
      options: {
        help: 'Hjælp',
        fullscreen: 'Fuld skærm',
        codeview: 'HTML-Visning',
      },
      paragraph: {
        paragraph: 'Afsnit',
        outdent: 'Formindsk indryk',
        indent: 'Forøg indryk',
        left: 'Venstrestillet',
        center: 'Centreret',
        right: 'Højrestillet',
        justify: 'Blokjuster',
      },
      color: {
        recent: 'Nyligt valgt farve',
        more: 'Flere farver',
        background: 'Baggrund',
        foreground: 'Forgrund',
        transparent: 'Transparent',
        setTransparent: 'Sæt transparent',
        reset: 'Nulstil',
        resetToDefault: 'Gendan standardindstillinger',
      },
      shortcut: {
        shortcuts: 'Genveje',
        close: 'Luk',
        textFormatting: 'Tekstformatering',
        action: 'Handling',
        paragraphFormatting: 'Afsnitsformatering',
        documentStyle: 'Dokumentstil',
        extraKeys: 'Extra keys',
      },
      help: {
        'insertParagraph': 'Indsæt paragraf',
        'undo': 'Undoes the last command',
        'redo': 'Redoes the last command',
        'tab': 'Tab',
        'untab': 'Untab',
        'bold': 'Set a bold style',
        'italic': 'Set a italic style',
        'underline': 'Set a underline style',
        'strikethrough': 'Set a strikethrough style',
        'removeFormat': 'Clean a style',
        'justifyLeft': 'Set left align',
        'justifyCenter': 'Set center align',
        'justifyRight': 'Set right align',
        'justifyFull': 'Set full align',
        'insertUnorderedList': 'Toggle unordered list',
        'insertOrderedList': 'Toggle ordered list',
        'outdent': 'Outdent on current paragraph',
        'indent': 'Indent on current paragraph',
        'formatPara': 'Change current block\'s format as a paragraph(P tag)',
        'formatH1': 'Change current block\'s format as H1',
        'formatH2': 'Change current block\'s format as H2',
        'formatH3': 'Change current block\'s format as H3',
        'formatH4': 'Change current block\'s format as H4',
        'formatH5': 'Change current block\'s format as H5',
        'formatH6': 'Change current block\'s format as H6',
        'insertHorizontalRule': 'Insert horizontal rule',
        'linkDialog.show': 'Vis Link Dialog',
      },
      history: {
        undo: 'Fortryd',
        redo: 'Annuller fortryd',
      },
      specialChar: {
        specialChar: 'SPECIAL CHARACTERS',
        select: 'Vælg special karakterer',
      },
    },
  });
})(jQuery);
